<script>

  const box = require('@/assets/got-box.jpg')

  export default {
    methods: {
      boxPath: function () {
        return box
      }
    }
  }
</script>
