import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"app"}},[_c('sn-toolbar',{model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}}),_c('sn-nav-drawer',{attrs:{"app":""},model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}}),_c('sn-snackbar',{model:{value:(_vm.snackbar.open),callback:function ($$v) {_vm.$set(_vm.snackbar, "open", $$v)},expression:"snackbar.open"}},[_c('div',{staticClass:"text-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.snackbar.message)}})])]),_c(VMain,[_c(VContainer,[_c(VCard,[_c(VCardTitle,[_vm._v(" Guild of Thieves Rankings ")]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"loading-text":"Loading... Please wait","server-items-length":_vm.totalItems,"items-per-page":10,"footer-props":{ itemsPerPageOptions: [ 10, 25, 50 ] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('sn-user-btn',{attrs:{"user":item.user,"x-small":""}},[_vm._v(_vm._s(item.user.name))])]}},{key:"item.current",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.display(item.current))+" ")]}},{key:"item.projected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.display(item.projected))+" ")]}}])})],1)],1)],1)],1),_c('sn-footer',{attrs:{"app":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }