import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VContainer,[_c(VRow,[_c(VCol,[_c('span',{staticClass:"font-weight-black mr-1"},[_vm._v("Title:")]),_vm._v(_vm._s(_vm.game.title)+" ")])],1),_c(VDivider,{staticClass:"my-1"}),_c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.game.id))]),_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("Turn:")]),_vm._v(" "+_vm._s(_vm.game.turn))]),_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v("Current Player:")]),_vm._v(" "+_vm._s(_vm.nameFor(_vm.cp)))]),_c(VCheckbox,{ref:"live",attrs:{"label":"Live Updates"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c(VCol,{attrs:{"cols":"4"}},[(_vm.game.status == 2)?_c(VRow,[_c(VCol,[_c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mt-5",attrs:{"small":"","color":"info","dark":""}},on),[_vm._v("Results")])]}}],null,false,1663175664),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c('sn-results-table',{attrs:{"game":_vm.game}})],1)],1)],1)],1):_c(VRow,[_c(VCol,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"font-weight-black",attrs:{"align":"center"}},[_vm._v(" Jewels ")])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[_c(VCard,{attrs:{"color":"green","height":"90","min-width":"90","width":"90"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('space-image',_vm._g({attrs:{"value":_vm.game.jewels}},on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip(_vm.game.jewels)))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }