<template>
  <li>
    Passed.
  </li>
</template>

<script>
  export default {
    name: 'sn-log-pass-msg',
    props: [ 'message', 'game' ]
  }
</script>
