import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticStyle:{"min-height":"100%"},attrs:{"width":"500","fixed":"","right":"","light":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"100%"}},[_c(VToolbar,{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"color":"green","dark":"","dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Chat")])],1),_c(VContainer,{ref:"chatbox",staticStyle:{"overflow-y":"auto"},attrs:{"fluid":""}},[_vm._l((_vm.messages),function(message,index){return _c('sn-message',{key:index,staticClass:"my-2",attrs:{"message":message,"id":_vm.msgId(index),"game":_vm.game}})}),(_vm.loading || _vm.sending)?_c(VProgressLinear,{attrs:{"color":"green","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"messagebox"})],2),_c(VSpacer),_c(VDivider),_c(VCard,[_c(VCardText,[_c(VTextarea,{attrs:{"auto-grow":"","color":"green","label":"Message","placeholder":"Type Message.  Press 'Enter' Key To Send.","rows":"1","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }