import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"app"}},[_c('sn-toolbar',{model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}}),_c('sn-nav-drawer',{attrs:{"app":""},model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}}),_c('sn-snackbar',{model:{value:(_vm.snackbar.open),callback:function ($$v) {_vm.$set(_vm.snackbar, "open", $$v)},expression:"snackbar.open"}},[_c('div',{staticClass:"text-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.snackbar.message)}})])]),_c(VMain,[_c(VContainer,[_c(VCard,[_c(VRow,[_c(VCol,{staticStyle:{"min-width":"80px","max-width":"80px"},attrs:{"cols":"1"}},[_c(VImg,{staticClass:"ma-2",attrs:{"min-width":"74","max-width":"74","src":_vm.boxPath()}})],1),_c(VCol,[_c(VCardTitle,[_vm._v(" Guild of Thieves ")]),_c(VCardSubtitle,[_vm._v(" Invitations ")])],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"item-class":_vm.striped,"expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"loading-text":"Loading... Please wait","server-items-length":_vm.totalItems,"items-per-page":10,"footer-props":{ itemsPerPageOptions: [ 10, 25, 50 ] },"show-expand":"","single-expand":""},on:{"click:row":_vm.expandRow,"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(!item.public)?_c(VIcon,{staticClass:"mb-2",attrs:{"small":""}},[_vm._v("mdi-lock")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('sn-user-btn',{attrs:{"user":_vm.creator(item),"x-small":""}},[_vm._v(" "+_vm._s(_vm.creator(item).name)+" ")])],1)],1)]}},{key:"item.players",fn:function(ref){
var item = ref.item;
return [_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.users(item)),function(user){return _c(VCol,{key:user.id,staticClass:"my-1",attrs:{"cols":"12","md":"6"}},[_c('sn-user-btn',{attrs:{"user":user,"x-small":""}},[_vm._v(" "+_vm._s(user.name)+" ")])],1)}),1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('sn-expanded-row',{attrs:{"span":headers.length,"item":item},on:{"action":function($event){return _vm.action($event)}}})]}}])})],1)],1)],1)],1),_c('sn-footer',{attrs:{"app":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }