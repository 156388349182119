import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":_vm.id}},[_c(VSystemBar,{staticClass:"white--text",attrs:{"color":"green"}},[_c('sn-user-btn',{attrs:{"user":_vm.creator,"x-small":"","color":_vm.colorByUser(_vm.creator)}}),_c('span',{staticClass:"ml-1 white--text"},[_vm._v(_vm._s(_vm.creator.name))]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,_vm._g({attrs:{"color":"white"}},on),[_vm._v("help")])],1)]}}])},[_c('span',[_vm._v("Help")])]),_c('div',[_vm._v(_vm._s(_vm.message.id))])],1),_c(VCardText,[_c('div',[_vm._v(" "+_vm._s(_vm.message.text)+" ")]),_c(VDivider),_c('div',{staticClass:"caption"},[_vm._v(" Created: "+_vm._s(Date(_vm.message.createdAt))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }