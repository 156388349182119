import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-3"},[_c(VCardText,[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('sn-user-btn',{attrs:{"user":_vm.userFor(_vm.player),"color":_vm.colorByPlayer(_vm.player),"small":""}},[_vm._v(" "+_vm._s(_vm.nameFor(_vm.player))+" ")])],1),_c('div',[_c('strong',[_vm._v("Score:")]),_vm._v(" "+_vm._s(_vm.player.score))]),(_vm.player.passed)?_c('div',[_c('strong',[_vm._v("Passed")])]):_vm._e()]),_c(VCol,{attrs:{"cols":"4","md":"3"}},[_c('sn-deck',{attrs:{"deck":_vm.player.hand,"show":false,"size":"small"}},[_vm._v("Hand")])],1),_c(VCol,{attrs:{"cols":"4","md":"3"}},[_c('sn-deck',{attrs:{"deck":_vm.player.drawPile,"show":false,"size":"small"}},[_vm._v("Draw")])],1),_c(VCol,{attrs:{"cols":"4","md":"3"}},[_c('sn-deck',{attrs:{"deck":_vm.player.discardPile,"show":true,"size":"small"}},[_vm._v("Discard")])],1)],1)],1),(_vm.showBtns)?_c(VDivider):_vm._e(),(_vm.showBtns)?_c(VCardActions,[_c(VRow,{attrs:{"no-gutters":"","justify":"space-around"}},[(_vm.canShow)?_c(VCol,{attrs:{"align":"center"}},[_c(VBtn,{attrs:{"small":"","width":"64","color":"info","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show')}}},[_vm._v(" Hand ")])],1):_vm._e(),(_vm.canPass)?_c(VCol,{attrs:{"align":"center"}},[_c(VBtn,{attrs:{"small":"","width":"64","color":"info","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('pass')}}},[_vm._v(" Pass ")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }