import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[_c(VTooltip,{attrs:{"disabled":!_vm.canReset,"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","disabled":!_vm.canReset},on:{"click":function($event){return _vm.$emit('action', { action: 'reset' })}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Reset")])]),_c(VTooltip,{attrs:{"disabled":!_vm.canUndo,"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"disabled":!_vm.canUndo,"icon":""},on:{"click":function($event){return _vm.$emit('action', { action: 'undo' })}}},on),[_c(VIcon,[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("Undo")])]),_c(VTooltip,{attrs:{"disabled":!_vm.canRedo,"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","disabled":!_vm.canRedo},on:{"click":function($event){return _vm.$emit('action', { action: 'redo' })}}},on),[_c(VIcon,[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("Redo")])]),_c(VTooltip,{attrs:{"disabled":!_vm.canFinish,"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","disabled":!_vm.canFinish},on:{"click":function($event){return _vm.$emit('action', { action : _vm.finishAction })}}},on),[_c(VIcon,[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Finish")])]),_c(VTooltip,{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('action', { action : 'refresh' })}}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }