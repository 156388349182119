import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"absolute":"","clipped-left":"","clipped-right":"","color":"green","dark":"","app":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"1"}},[_c(VTooltip,{attrs:{"bottom":"","color":"info","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VAppBarNavIcon,_vm._g({on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', !_vm.value)}}},on))]}}])},[_c('span',[_vm._v("Menu")])])],1),_c(VCol,[_vm._t("default")],2),_c(VCol,{attrs:{"cols":"auto","align":"right"}},[_c(VCard,{attrs:{"to":{ name: 'sng-home' },"color":"white","min-width":"100"}},[_c(VImg,{attrs:{"width":"100","src":_vm.imagePath('logo100')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }